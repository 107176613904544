// basic styles
//@import "style_base.scss";

// breakpoint max-width: 24rem
@media screen and (max-width: 24rem) {
  @import "ContentElements/blog/blog_max_24rem.scss";
}



